import { Loader } from '@googlemaps/js-api-loader';
import { mapStyle, autocompleteOptions } from '../constants/mapStyles';

/**
 * Instantiate the googlemaps loader.
 */
const loader = new Loader({
  apiKey: process.env.GOOGLE_MAP_API_KEY,
  version: 'weekly',
  libraries: ['places'],
});

/**
 * Extract control position from googlemaps core library.
 * @returns {*} The control position enum
 */
export const getControlPosition = async () => {
  const { ControlPosition } = await loader.importLibrary('core');
  return ControlPosition;
};

/**
 * Extract event from googlemaps core library.
 * @returns {*} TGoogle core event
 */
export const getCoreEvent = async () => {
  const { event } = await loader.importLibrary('core');
  return event;
};

/**
 * Extract DirectionsService from googlemaps core library.
 * @returns {*} TGoogle maps DirectionsService
 */
export const getDirectionsService = async () => {
  const { DirectionsService } = await loader.importLibrary('routes');
  return DirectionsService;
};

/**
 * Extract DirectionsRenderer from googlemaps core library.
 * @returns {*} TGoogle maps DirectionsRenderer
 */
export const getDirectionsRenderer = async () => {
  const { DirectionsRenderer } = await loader.importLibrary('routes');
  return DirectionsRenderer;
};

/**
 * Draw google maps on provided element.
 * @param {*} targetElement - The element that will hold the map.
 * @returns {*} Google map
 */
export const drawMap = async (targetElement, center) => {
  const { Map } = await loader.importLibrary('maps');
  return new Map(targetElement, {
    zoom: 4,
    mapTypeControl: false,
    streetViewControl: false,
    controlSize: 24,
    center: center,
    styles: mapStyle,
  });
};

/**
 * Set geometry on a google map.
 * @param {*} geometry - The geometry to add.
 * @param {*} map - Google map
 */
export const setOnMap = (geometry, map) => {
  geometry.setMap(map);
};

/**
 * Set control to a google map.
 * @param {*} map - Google map
 * @param {Document} targetDiv - The control div element.
 */
export const addControlToMap = async (map, targetDiv) => {
  const ControlPosition = await getControlPosition();
  map.controls[ControlPosition.TOP_LEFT].push(targetDiv);
};

export const setMapBounds = async (map, geometry) => {
  const bounds = await getBoundsFromPolygon(geometry);
  map.setOptions({
    restriction: {
      latLngBounds: bounds,
    },
  });
};

/**
 * Draw a circle using google maps.
 * @param {Number} map - Longitude of the center fof the circle
 * @param {Number} targetDiv - Latitude of the center of the circle.
 * @param {Number} radius - Radius of the circle.
 * @param {String} color - Color of the circle.
 * @returns {*} Google maps circle
 */
export const drawCircle = async (lat, long, radius, color = '#FF0000') => {
  const { Circle } = await loader.importLibrary('maps');
  return new Circle({
    strokeColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: color,
    fillOpacity: 0.35,
    center: { lat: Number(lat), lng: Number(long) },
    radius: radius,
  });
};

/**
 * Draw a polygon using google maps.
 * @param {Array} points - Array consisting LatLng literals
 * @param {boolean} editable - Whether the polygon is editable.
 * @param {String} color - Color of the polygon.
 * @returns {*} Google maps polygon
 */
export const drawPolygon = async (
  boundary,
  editable = false,
  color = '#FF0000',
) => {
  const { Polygon } = await loader.importLibrary('maps');
  const points = await getPointsFromBoundary(boundary);

  return new Polygon({
    paths: points,
    strokeColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: color,
    fillOpacity: 0.35,
    editable: editable,
    draggable: editable,
  });
};

/**
 * Setup google maps drawing manager.
 * @returns {*} Google maps drawing manager
 */
export const drawingManager = async () => {
  const { DrawingManager, OverlayType } = await loader.importLibrary('drawing');
  const ControlPosition = await getControlPosition();
  return new DrawingManager({
    drawingControlOptions: {
      drawingControl: true,
      drawingModes: [OverlayType.POLYGON],
      position: ControlPosition.TOP_LEFT,
    },
    polygonOptions: {
      editable: true,
      draggable: true,
      clickable: true,
    },
  });
};

/**
 * Check that the polygon is within specified bounds.
 * @param {*} parentGeometry - Specified bounds
 * @param {*} geometry - Polygon
 * @returns {boolean} Check result
 */
export const checkPolygonIsWithinBounds = async (geometry, parentGeometry) => {
  const { poly } = await loader.importLibrary('geometry');
  let pointsOutside = 0;

  geometry.getPath().forEach(element => {
    poly.containsLocation(element, parentGeometry) ? null : pointsOutside++;
  });

  return pointsOutside > 0 ? false : true;
};

/**
 * Show drawing manager.
 * @param {*} drawingManager - Google maps drawing manager.
 * @returns {boolean} Check result
 */
export const hideDrawingManager = drawingManager => {
  drawingManager.setOptions({
    drawingControl: false,
    drawingMode: null,
  });
};

/**
 * Hide drawing manager.
 * @param {*} drawingManager - Google maps drawing manager.
 * @returns {boolean} Check result
 */
export const showDrawingManager = drawingManager => {
  drawingManager.setOptions({
    drawingControl: true,
  });
};

export const autoComplete = async targetElement => {
  const { Autocomplete } = await loader.importLibrary('places');
  return new Autocomplete(targetElement, autocompleteOptions);
};

export const getBoundsFromPolygon = async geometry => {
  const { LatLngBounds } = await loader.importLibrary('core');
  const bounds = new LatLngBounds();

  geometry.getPath().forEach(element => {
    bounds.extend(element);
  });

  return bounds;
};

export const getPointsFromBoundary = async boundary => {
  const { LatLng } = await loader.importLibrary('core');
  let points = [];

  boundary.forEach(element => {
    let coordinates = new LatLng(element[0], element[1]);
    points.push(coordinates);
  });

  return points;
};
